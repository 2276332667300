@import url('@src/total.less');
.container {
  .clear();
}
.title-line {
  font-size: 30px;
  font-weight: bold;
  line-height: 48px;

  color: @heading-color;
}
.sub-title-line {
  font-size: 16px;
  line-height: 25px;
}
.notice-line {
  font-size: 12px;
  line-height: 28px;

  height: 48px;
  padding: 0 12px;

  color: @error-color;
}
.submit {
  font-size: 20px;

  display: block;

  width: 100%;
  height: 50px;
  margin-top: 24px;

  cursor: no-drop;

  color: @text-color-secondary;
  background-color: @grey-2;

  .border();
  .border-radius();
  &.active {
    cursor: pointer;

    color: #fff;
    background-color: @primary-color;

    .border(1px, @primary-color);
  }
}

.identity-line {
  display: flex;
  justify-content: space-between;

  padding: 48px 0;
  .identity-block {
    display: inline-block;

    width: 178px;

    text-align: center;
    > span {
      font-size: 14px;
      line-height: 48px;

      color: #666;
    }
    label {
      position: relative;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 122px;
      margin: 0;
      padding: 0;

      cursor: pointer;

      background-size: 100% 100%;
      &.front {
        background: url('~@imgs/front.jpeg') center no-repeat;
        background-size: 100% 100%;
      }
      &.back {
        background: url('~@imgs/back.png') center no-repeat;
        background-size: 100% 100%;
      }
      > input {
        position: absolute;
        top: 0;
        left: 0;

        overflow: hidden;

        width: 0;
        height: 0;
      }
      > span {
        font-size: 16px;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 34px;
        height: 34px;

        color: #fff;
        border-radius: 50%;
        background-color: #2a52bb;
      }
      > img {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;

        width: 178px;
        height: 122px;

        border: 1px solid #ddd;
        background: #fff;
      }
    }
  }
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;