@bgColor: #f5f5f9;
@primary-color: #063bb4;
@header-color: #333;
@header-sub-color: #666;
@text-color: #000;
@text-color-secondary: #999;
@error-color: #ff5b4c;
@success-color: #31ae11;
@link-color: #3c72f6;
@warning-color: #f93;
@border-radius-base: 4px;
@font-size-base: 14px;
@font-size-sm: 12px;
@box-shadow-base: rgb(183, 187, 200) 0px 1px 3px;
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08),
//   0 9px 28px 8px rgba(0, 0, 0, .05);
@line-height-base: 1.5715;
@border-color-base: hsv(0, 0, 85%);
@border-color-split: hsv(0, 0, 94%);

@primary-1: #eef3ff; // 主色
@primary-2: #8eb0ff;
@primary-3: #59f;
@primary-4: #457bd9;
@primary-5: #004eee;
@primary-7: #002999;
@primary-8: #001b73;
@primary-9: #000f4d;
@primary-10: #000626;
@vice-1: #e6feff; // 副色
@vice-2: #9becf2;
@vice-3: #6edae6;
@vice-4: #45c5d9;
@vice-5: #21afcc;
@vice-6: #0099bf;
@vice-7: #007599;
@vice-8: #005473;
@vice-9: #00364d;
@vice-10: #001926;
@grey-1: #f9fafb; // 中性色
@grey-2: #f4f5f7;
@grey-3: #eeeff2;
@grey-4: #dcdee4;
@grey-5: #b7bbc8;
@grey-6: #7e889c;
@grey-7: #5c6478;
@grey-8: #1f2229;

.border(@width: 1px, @color: #ddd) {
  border: @width solid @color;
}
.border-radius(@radius: @border-radius-base) {
  border-radius: @radius;
}
.clear() {
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}
