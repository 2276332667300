@import url('@src/total.less');
.container {
  position: relative;

  box-sizing: border-box;
  height: 50px;
  padding-left: 100px;

  .border();
  .border-radius();
}
.zone {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100px;
  height: 48px;
}
.zone-input {
  font-size: 20px;

  width: 72px;
  height: 48px;
  padding: 0;

  text-align: center;

  color: @heading-color;
  border: 0;
  border-radius: @border-radius-base;
  outline: none;
}
.zone-block-arrow {
  position: absolute;
  top: 0;
  right: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 100%;

  cursor: pointer;
}
.zone-tel-search {
  margin-bottom: 8px;
}

.zone-list {
  overflow: auto;

  width: 400px;
  height: 304px;
  li {
    font-size: @font-size-base;
    line-height: @line-height-base;

    cursor: pointer;
  }
}

.zone-tel {
  display: inline-block;

  width: 72px;
}

.zone-li {
  &:hover {
    background-color: @grey-2;
  }
  &.active {
    background-color: @primary-1;
  }
}
.phone-input {
  font-size: 20px;

  width: 100%;
  height: 48px;
  padding: 0 16px;

  color: @heading-color;
  border: 0;
  border-radius: @border-radius-base;
  background: #fff;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;