@import url('@src/total.less');
.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;

  height: 56px;
  > img {
    width: 12px;
    margin-right: 16px;
  }
  > span {
    font-size: @font-size-base;

    color: #7e889c;
  }
}
.block {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  box-sizing: border-box;
  margin-bottom: 12px;
  padding: 12px 24px;

  cursor: pointer;

  color: @text-color;
  box-shadow: @box-shadow-base;

  .border-radius();
  .border(1px, @border-color-split);
}

.block-left {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.block-left-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;

  height: 48px;
  padding-right: 8px;

  color: @grey-8;
  &::after {
    content: '：';
  }
}

.block-left-content {
  line-height: 24px;

  flex: 1;

  padding: 12px 0;
}

.block-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;

  height: 48px;
  padding-left: 24px;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@grey-1: #F9FAFB;@grey-2: #F4F5F7;@grey-3: #EEEFF2;@grey-4: #DCDEE4;@grey-5: #B7BBC8;@grey-6: #7E889C;@grey-7: #5C6478;@grey-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;